export const WORDS = [
  'probe',
  'drone',
  'pylon',
  'scout',
  'flare',
  'units',
  'group',
  'ghost',
  'sarah',
  'queen',
  'boxer',
]
